/* animations */

.fade-in-right {
    animation: fadeInRight 2s ease forwards;
}

@keyframes fadeInRight {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.fade-in-left {
    animation: fadeInLeft 2s ease forwards;
}

@keyframes fadeInLeft {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


.fade-out-left {
    animation: fadeOutLeft 2s ease forwards;
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
        /* display: none; */
    }
}

.fade-out-right {
    animation: fadeOutRight 2s ease forwards;
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
        /* display: none; */
    }
}


.fade-in-top {
    animation: fadeInTop 2s ease forwards;
}

@keyframes fadeInTop {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-bottom {
    animation: fadeInBottom 2s ease forwards;
}

@keyframes fadeInBottom {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes dropShowEffect {
    0% {
        height: 0;
        /* width: 0; */
        /* padding: 0; */
        opacity: 1;
        /* border: none; */
    }

    100% {
        height: 520px;
        /* width: 400px; */
        /* padding: 1em; */
        opacity: 1;
    }
}


/* tooltip effects */

.tooltip {
    cursor: pointer;
    position: relative;
    /* display: inline-block; */
}

.tooltip::after {
    content: attr(data-title);
    position: absolute;
    bottom: 100%;
    /* Position above the element */
    /* left: 50%; */
    right: 20%;
    transform: translateX(-50%);
    background-color: #103F6D;
    /* Custom background color */
    color: white;
    /* Custom text color */
    padding: 0.5em;
    border-radius: 0.25em;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    z-index: 2000;
}

.tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}


.movingBackgroundColor:hover::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* padding: 0.5em; */
    /* border-radius: inherit; */
    border-radius: 0.5em;
    /* background-color: #ccc; */
    /* background-color: #36454F; */
    /* background-color: #708090; */
    background-color: black;
    color: white;
    transition: all 2s ease;
    /* animation: movingBackgroundColor 2s ease forwards; */
}


@keyframes movingBackgroundColor {

    0% {
        width: 0%;
    }

    30% {
        left: 0%;
        right: auto;
        width: 100%;
    }

    32% {
        left: auto;
        right: 0%;
        width: 100%;
    }

    60% {
        right: 0%;
        left: auto;
        width: 50%;
    }


    100% {
        width: 100%;
    }

}

.movingBackgroundColor a.active {
    border-radius: 0.25em;
    padding: 0.1em 0.2em;
    background-color: black;
    color: inherit;
}


/* loader animation */
.loader-container {
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(200, 200, 200, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 1001;
}

.loader {
    border: 1em solid #f3f3f3;
    border-top: 1em solid #3498db;
    border-radius: 50%;
    width: 5.5em;
    height: 5.5em;
    animation: spin 2s linear infinite;
    position: absolute;
    /* z-index: 1001; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width:768px) {
    .loader {
        width: 50px;
        height: 50px;
        margin: auto;
        top: 50%;
        left: 45%;
    }
}