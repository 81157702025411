/* Service Cover Container */
.service-cover-container {
    width: 100%;
}

/* Service Cover Section */
.service-cover-container .service-cover {
    height: 37em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.25em;
    background: linear-gradient(rgba(36, 93, 203, 1), rgb(48, 101, 205));
    background-blend-mode: overlay;
    position: relative;
    transition: all 0.5s linear;
    min-height: 350px;
    /* border: 1px solid black; */
}

.service-cover-container .service-cover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../assets/img/services/image15.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.07;
    z-index: 0;
}


.service-cover-container .service-cover .text-section-header {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 3.75rem;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: white;
    margin: auto;
    /* border: 1px solid black; */
}

.service-cover-container .service-cover div.text-section > p {
    width: 85%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2em;
    font-weight: normal;
    line-height: 1.4;
    color: white;
    text-align: center;
    margin: 2.125em auto;
}

@media screen and (max-width: 768px) {
    .service-cover-container .service-cover {
        height: auto;
        padding: 0.5rem;
    }

    .service-cover-container .service-cover h1.text-section-header {
        width: 100%;
        font-size: 2.25rem;
    }

    .service-cover-container .service-cover div.text-section > p {
        width: 60%;
        font-size: 1em;
    }
}

@media screen and (max-width: 640px) {
    .service-cover-container .service-cover h1.text-section-header {
        width: 100%;
        font-size: 1.475rem;
    }

    .service-cover-container .service-cover div.text-section > p {
        width: 80%;
        font-size: 1em;
    }
}