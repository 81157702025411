/* TermsOfService.css */
.terms-container {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    margin: 0 auto;
    padding: 2rem;
    max-width: 900px;
    background-color: #fff;
}

/* Header Styling */
.terms-container h1 {
    text-align: center;
    color: #1e56d6; 
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

/* Paragraph Styling */
.terms-container p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.terms-container strong {
    font-weight: bold;
}

.terms-container a {
    color: #2980b9;
    text-decoration: none;
}

.terms-container a:hover {
    text-decoration: underline;
}

/* Section Headers */
.terms-container .section-header {
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #eaeaea;
    padding-bottom: 0.5rem;
}

.terms-container h2 {
    color: #1e56d6; 
    font-size: 1.8rem;
}

.terms-container h3 {
    font-size: 1.5rem;
    color: #000000; 
    margin-top: 1rem;
}

.terms-container ol {
    padding-left: 1.5rem;
    list-style-type: decimal; 
    font-size: 1rem;
    margin-bottom: 1rem;
}

.terms-container ol li {
    margin-bottom: 0.75rem;
}

.terms-container ol li strong {
    color: #2c3e50;
}

.terms-container ul {
    list-style-type: none;
    padding-left: 0;
}

.terms-container ul li {
    font-size: 1rem;
    margin-bottom: 0.75rem;
}

/* Links Styling */
.terms-container a {
    font-weight: bold;
}

.terms-container p a {
    text-decoration: underline;
}

.terms-container p a:hover {
    color: #2c3e50;
}

.terms-container .highlight {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    margin-top: 1.5rem;
}

.terms-container section {
    padding-bottom: 2rem;
}

.terms-container .note {
    font-style: italic;
    color: #7f8c8d;
    margin-top: 1.5rem;
}
