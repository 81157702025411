/* Root Variables for Light Mode */
:root {
    --primary-color: #2563eb;
    --secondary-color: #10b981;
    --background-color: #f3f4f8;
    --card-bg-color: #ffffff;
    --text-color: #444;
    --heading-color: #222;
    --hover-color: rgba(37, 99, 235, 0.1);
    --shadow-color: rgba(0, 0, 0, 0.08);
}

/* Root Variables for Dark Mode */
@media (prefers-color-scheme: dark) {
    :root {
        --primary-color: #93c5fd;
        --secondary-color: #34d399;
        --background-color: #1f2937;
        --card-bg-color: #374151;
        --text-color: #e5e7eb;
        --heading-color: #f3f4f6;
        --hover-color: rgba(147, 197, 253, 0.1);
        --shadow-color: rgba(0, 0, 0, 0.3);
    }
}

/* Main Container */
.privacy-policy-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 50px;
    background: var(--background-color);
    font-family: 'Inter', sans-serif;
    line-height: 1.8;
    color: var(--text-color);
    box-sizing: border-box;
}

/* Sidebar Styling */
.sidebar {
    width: 300px;
    background-color: var(--card-bg-color);
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 16px var(--shadow-color);
    position: sticky;
    top: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: fit-content;
}

.sidebar:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Sidebar Header */
.sidebar h3 {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 25px;
    border-bottom: 2px solid #eaeaea;
    padding-bottom: 10px;
}

/* Sidebar Links */
.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar ul li:hover {
    transform: translateX(10px);
}

.sidebar a {
    font-size: 17px;
    color: var(--primary-color);
    text-decoration: none;
    display: block;
    padding: 12px 16px;
    border-radius: 8px;
    transition: background-color 0.3s ease, padding-left 0.3s ease;
    font-weight: 500;
}

.sidebar a:hover {
    color: var(--card-bg-color);
    background-color: var(--primary-color);
    padding-left: 20px;
}

.sidebar a.active {
    background-color: var(--secondary-color);
    color: var(--card-bg-color);
    padding-left: 20px;
    font-weight: 600;
}

/* Content Area */
.content {
    flex: 1;
    background: var(--card-bg-color);
    padding: 50px;
    border-radius: 20px;
    box-shadow: 0 6px 20px var(--shadow-color);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.content:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

/* Headings */
.content h1, .content h2, .content h3 {
    color: var(--heading-color);
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.4;
}

.content h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
}

.content h2 {
    font-size: 28px;
    border-bottom: 2px solid var(--hover-color);
    padding-bottom: 10px;
    margin-top: 40px;
}

.content h3 {
    font-size: 22px;
    margin-top: 30px;
}

/* Paragraphs */
.content p {
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: justify;
}

/* Hyperlinks */
.content a {
    color: var(--primary-color);
    text-decoration: underline;
    transition: color 0.3s ease;
}

.content a:hover {
    color: var(--secondary-color);
}

/* Lists */
.content ul, .content ol {
    padding-left: 40px;
    margin-bottom: 30px;
}

.content ul li, .content ol li {
    font-size: 18px;
    margin-bottom: 12px;
}

/* Section Separator */
.content .section-separator {
    margin: 40px 0;
    border-top: 1px dashed var(--hover-color);
}

/* Back-to-Top Button */
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--secondary-color);
    color: var(--card-bg-color);
    padding: 15px;
    border-radius: 50%;
    font-size: 22px;
    display: none;
    box-shadow: 0 4px 16px var(--shadow-color);
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.back-to-top:hover {
    background-color: var(--primary-color);
    transform: scale(1.15);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.content {
    animation: fadeIn 0.6s ease-out;
}

/* Responsiveness */
@media (max-width: 1200px) {
    .privacy-policy-container {
        padding: 30px;
    }

    .sidebar {
        width: 100%;
    }

    .content {
        padding: 40px;
    }
}

@media (max-width: 768px) {
    .privacy-policy-container {
        flex-direction: column;
        gap: 20px;
    }

    .content {
        padding: 30px;
    }

    .content h1 {
        font-size: 30px;
    }

    .content h2 {
        font-size: 24px;
    }

    .content p {
        font-size: 16px;
    }
}
