@tailwind base;
@tailwind components;
@tailwind utilities;



html {
  overflow-x: hidden;
}

body {
  /* overflow-x: hidden; */
  padding: 0px;
  margin: 0px;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* font-family: "Poppins", sans-serif; */
  font-style: normal;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width:768px) {
  .loader {
    width: 50px;
    height: 50px;
  }
}