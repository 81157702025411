.whatsapp-container {
    z-index: 600;
    right: 0;
    position: relative;
}

.sticky-element {
    position: fixed;
    bottom: 1em;
    right: 1em;
    z-index: 550;
    /* Lower z-index */
    padding: 10px;
}

.whatsapp-image {
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.5s ease;
}

.whatsapp-image:hover {
    width: 60px;
    box-shadow: 0 0 2em 1em #02a029;
    transform: rotateZ(-10deg);
}

.whatsapp-form-popup {
    position: absolute;
    bottom: -15em;
    right: -8em;
    transform: translate(-50%, -50%);
    /* background-color: rgba(255, 255, 255, 0.9); */
    padding: 1em 1.5em;
    margin: 0;
    z-index: 650;
    height: 0;
    width: 400px;
    /* width: 0; */
    overflow: hidden;
    border-radius: 0.5em;
    /* border: 1px solid black; */
    transition: all 1s linear;
}


.whatsapp-form {
    position: relative;
    z-index: 600;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 3em;
    background-color: white;
    border-radius: 2em;
    border-bottom-right-radius: 0;
    max-width: 400px;
    margin: auto;
    opacity: 1;
    /* transition: all 1s linear; */
}

.whatsapp-form::before {
    content: "";
    position: absolute;
    /* background-color: #45a049; */
    background-color: transparent;
    z-index: 2;
    bottom: 0px;
    right: -45px;
    width: 45px;
    height: 30px;
    border-bottom-left-radius: 3em;
    overflow: visible;
    /* border-top-right-radius: 3.125cqw; */
    /* box-shadow: -10px -3px 0 3px rgb(167, 118, 118); */
    box-shadow: -24px 0.5px 0 0 white;
}


/* animation for drop down affect of the element */

.drop-show-effect {
    animation: dropShowEffect 1s ease forwards;
}

.whatsapp-form div {
    margin-bottom: 15px;
}

.whatsapp-form label {
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
    font-family: "Poppins";
    font-weight: 400;
}

.whatsapp-form input,
.whatsapp-form textarea,
.whatsapp-form select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    outline: none;
}

.whatsapp-form input:hover,
.whatsapp-form textarea:hover,
.whatsapp-form select:hover {
    border: 2px solid #ccc;
    box-shadow: 0 0 5px 0 #aaa;
}



.whatsapp-form button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    font-family: "Poppins";
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
}

.whatsapp-form button:hover {
    background-color: #45a049;
}

.close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    font-size: 1.25em;
    width: 2.2em;
    height: 2.2em;
    /* padding: 0.5em; */
    margin: 0;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
}

.close-btn label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    color: white;
    margin: 0;
    padding: 0;
    cursor: inherit;
}

@media (max-width: 600px) {
    .whatsapp-form-popup {
        position: fixed;
        left: 50vw;
        /* right: -10em; */
        width: 90vw;
        padding: 0;
    }

    .sticky-element {
        bottom: 0.5em;
        right: 0.5em;
    }

    .whatsapp-image {
        width: 2.5em;
    }
}