.testimonials-container {
    /* border: 2px solid black; */
    padding: 5em;
}

.testimonials {
    padding: 0;
    /* border: 2px solid black; */
}

.testimonial-header {
    /* border: 2px solid black; */
    font-size: 1.5em;
    font-family: Inter;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.03em;
    margin: 0;
    margin-bottom: 2em;
}

.testimonial-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
    /* border: 1px solid black; */
}

.testimonial-card-container {
    position: relative;
    border: solid 1px #d9d9d9;
    border-radius: 0.5em;
    width: 30%;
    height: fit-content;
    /* overflow-y: auto; */
    overflow-y: visible;
    overflow-x: visible;
    /* overflow-x: visible; */
    scrollbar-width: none;
    /* z-index: 100; */
    /* border: 1px solid black; */
}

.testimonial-card {
    /* background-color: white; */
    /* border: solid 1px #d9d9d9; */
    border-radius: inherit;
    padding: 1.25em;
    /* width: 48%; */
    font-family: Inter;
    min-height: 220px;
    max-height: 240px;
    overflow-y: auto;
    overflow-x: visible;
    position: relative;
    top: 0;
    /* z-index: 101; */
}

.testimonial-card.expanded {
    max-height: none;
}

.testimonial-service {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.03em;
    color: #1e1e1e;
    /* border: 2px solid black; */
    margin-bottom: 0;
    margin-top: 0.5em;
}

.testimonial-body {
    font-size: 0.9em;
    line-height: 1.4;
    /* border: 2px solid black; */
    margin-top: 0.2em;

    max-height: 150px;
    overflow: hidden;
    /* overflow-x: visible; */
    transition: max-height 0.3s ease;
}

.testimonial-body.expanded {
    max-height: none;
}

.read-more-text {
    cursor: pointer;
    color: #457FD7;
    font-weight: 600;
}


.rating {
    font-size: 1.25em;
    color: gold;
}

.reviewer {
    display: flex;
    align-items: top;
    margin-top: 0.625em;
    padding: 0;
    cursor: pointer;
    /* border: 1px solid black; */
}

.reviewer-name {
    padding-top: 0.4em;
    /* border: 1px solid black; */
    margin-left: 0.25em;
    font-size: 0.9em;
    font-weight: 600;
    color: #757575;
    /* overflow-x: visible; */
}

.reviewer img {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
}

/* .profile-link {
    display: block;
} */

@media screen and (max-width:1030px) {
    /* .testimonial-body {
        max-width: 300px;
    } */

    .testimonial-cards {
        /* flex-wrap: wrap; */
        flex-direction: column;
    }

    .testimonial-card-container {
        height: auto;
        width: 100%;
        margin: auto;
    }

    .testimonial-card {
        position: relative;
        width: 100%;
    }

    .testimonial-body {
        width: 100%;
    }
}

@media screen and (max-width:550px) {
    .testimonials-container {
        padding: 1.5em;
    }


}