/* --------------------------------------------- */
.brand .green {
    color: #498600;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.325em 1.25em;
    color: white;
    z-index: 1111;
    position: sticky;
    position: -webkit-sticky;
    /* position: fixed; */
    top: 0;
    background-color: rgb(252, 251, 251);
    /* border-radius: 0 0 2em 2em; */
    box-shadow: 0 0 1em #888;
    /* border: 1px solid black; */
}

.brand {
    /* border: 1px solid black; */
    margin: 0;
    margin-left: 1.6em;
    font-family: "FuturaMdBT", "Futura Md BT Medium", "Nunito Sans", sans-serif;
    font-size: 3.10625rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.061875rem;
    text-align: center;
    color: #4a4a4a;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    min-width: 200px;
    transition: all linear 0.5s;
}

img {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.hidden {
    opacity: 0;
    transform: translateY(-100%);
    /* display: none; */
}

.move-left {
    transform: translateX(-100%);
    opacity: 0;
    /* display: none; */
}

.show {
    opacity: 1;
    transform: translateY(0);
    /* display: block; */
}

#RippleNexus_logo {
    /* display: none; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 150;
    /* cursor: pointer; */
}



/* for small icon */

.menu-icon {
    display: none;
    /* border: 1px solid black; */
    font-size: 1em;
    /* width: 40px;
    height: 40px; */
    margin: 0;
    border-radius: 50%;
    padding: 0.1em 0.35em;
    font-size: 1.5em;
    /* background-color: #457fd7; */
    transition: all 0.5s ease;
}

.nav-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.21875em;
    margin: auto 6.50625em;
    padding: 0;
    /* border: 2px solid black; */
}

.nav-menu {
    list-style: none;
    display: flex;
    gap: 1.2em;
    margin-right: 1.25em;
}

.nav-menu li {
    display: inline;
    padding: 0.1em;
    position: relative;
    /* width: fit-content; */
    /* border: 2px solid red; */
    text-wrap: nowrap;
    /* border: 1px solid black;
    border-radius: 0.25em; */
}

.nav-menu a {
    flex-grow: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.10625em;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-decoration: none;
    border-radius: .25em;
    padding: 0.05em;
}

.nav-menu a:hover {
    color: white;
}

.quote-button {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.55625em;
    padding: 0.8em 1.2em;
    border-radius: 0.5em;
    border: solid 0.05625em black;
    background-color: #fff;
    color: black;
    cursor: pointer;
    transition: all linear 0.5s;
}


.quote-button label {
    flex-grow: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.10625em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: inherit;
    cursor: pointer;
}

.quote-button:hover {
    /* background-color: #45a049; */
    background-color: black;
    border: 1px solid white;
    color: white;
}


/* Container for the navigation menu */

.small-window-nav {
    position: fixed;
    top: 3.5em;
    right: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: #000;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    /* transform: translateX(100%); */
    z-index: 500;
}

.small-window-nav li {
    width: 100%;
    border-radius: 0.25em;
    /* border: 1px solid white; */
    text-align: left;
    transition: background-color 0.3s ease;
}

.small-window-nav a {
    color: white;
    font-size: 1em;
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 4px;

}

.small-window-nav li:hover {
    background-color: #444;
}

.show-menu .small-window-nav {
    transform: translateX(0);
}


@media (max-width: 940px) {
    .nav-menu {
        display: none;
    }

    .menu-icon {
        display: inline-block;
    }

    .brand {
        margin: auto;
        font-size: 2em;
    }

    .nav-right {
        margin: auto;
        flex-direction: row-reverse;
    }

    .quote-button {
        padding: 0.5em 1em;
    }
}

@media screen and (max-width:650px) {

    .navbar {
        height: auto;
        justify-content: space-between;
    }

    .quote-button {
        /* width: 5em; */
        font-size: 0.8em;
    }

    .brand {
        min-width: auto;
    }

    #R_logo {
        width: 2em;
    }
}

@media screen and (max-width:350px) {
    .quote-button {
        padding: 0.2em 0.5em;
        font-size: 0.6em;
        margin-left: 0.5em;
    }
}

/* for small icon */

.bg-color-dark {
    background-color: #000;
}

.bg-color-dark:hover,
.bg-color-dark:active {
    background-color: #666;
}

.bg-color-light {
    background-color: #457fd7;
}

.bg-color-light:hover,
.bg-color-light:active {
    background-color: #1c62cc;
}

/* .menu-icon:hover,
.menu-icon:active {
    background-color: #1c62cc;
} */